import { useRouter } from 'next/router';
import { useMeQuery } from 'generated/graphql';

type ComponentProps = {
  children: React.ReactNode;
};

const WithoutAuthWrapper: (
  WrappedComponent: React.ComponentType<Partial<ComponentProps>>
) => React.ComponentType = (WrappedComponent) => {
  const WithoutAuth: React.ComponentType = (props) => {
    const { push } = useRouter();

    const { loading, data } = useMeQuery();

    if (loading) {
      return <div />;
    }

    if (data?.me) {
      push('/');
      return <div />;
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...props} />;
  };

  return WithoutAuth;
};

export default WithoutAuthWrapper;
