import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';
import withoutAuth from 'components/withoutAuth';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { useApolloClient } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { actions as notificationsActions } from 'lib/redux/notifications';
import { login } from 'lib/utils';
import {
  AuthenticateAdminByEmailInput,
  MeDocument,
  useAuthenticateAdminByEmailMutation,
} from 'generated/graphql';

type FormData = { email: string; password: string };

const path = '/api/connect/google';

const addQueryString: (url: string, queryParams: Record<string, any>) => string = (
  url,
  queryParams
) => {
  const queryString = Object.keys(queryParams)
    .filter((key: any) => !!queryParams[key])
    .map((key: any) => `${key}=${queryParams[key]}`)
    .join('&');

  if (queryString.length > 0) {
    return `${url}?${queryString}`;
  }

  return url;
};

const { addErrors } = notificationsActions;

const useStyles = makeStyles({
  root: {
    width: '100%',
    margin: '1rem auto',
    textAlign: 'center',
  },
});

const Login: React.FC = () => {
  const apolloClient = useApolloClient();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { query, push } = useRouter();
  const { register, errors, handleSubmit } = useForm<FormData>({ mode: 'onBlur' });

  const [authenticateAdminByEmail, { loading }] = useAuthenticateAdminByEmailMutation();

  const onSubmit = async (input: AuthenticateAdminByEmailInput) => {
    try {
      const data = await authenticateAdminByEmail({
        variables: { input },
      });

      const { user, token } = data.data?.authenticateAdminByEmail || {};

      if (user && token) {
        await login(token);
      }

      try {
        apolloClient.writeQuery({
          query: MeDocument,
          data: { me: user },
        });
      } catch (e) {}

      push('/');
    } catch (e: any) {
      dispatch(addErrors(e));
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Box mt={3}>
          <Card>
            <CardHeader title="Login" />
            <Divider />
            <CardContent>
              <Button
                href={addQueryString(path, { redirectTo: query?.redirectTo })}
                variant="contained"
                color="primary"
              >
                GOOGLE CONNECT
              </Button>

              <Box mt={3}>
                <Divider variant="middle" />
              </Box>

              <Box mt={3}>
                <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        type="text"
                        variant="outlined"
                        placeholder="john@colette.club"
                        inputRef={register({
                          required: 'Email is required',
                          pattern: {
                            // eslint-disable-next-line no-useless-escape
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Email is invalid',
                          },
                        })}
                        error={!!errors.email}
                        helperText={errors.email && (errors.email.message as string)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        type="password"
                        fullWidth
                        label="Password"
                        name="password"
                        required
                        variant="outlined"
                        inputRef={register({
                          required: 'Password is required',
                        })}
                        error={!!errors.password}
                        helperText={errors.password && (errors.password.message as string)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" type="submit" disabled={loading}>
                        Login
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </div>
  );
};

export default withoutAuth(Login);
